<template>
  <div>
    <Header title="Step 3: Your Game Jam Summary." />
    <div class="summary px-4">
      <div class="flex gap-2 items-start" v-if="data">
        <div class="w-8/12">
          <div class="grid grid-cols-2 gap-2" v-if="data">
            <div class="bg-nColorTextInput rounded-2xl p-4">
              <div class="font-InterMedium text-nColorLightGray">
                Game Jam Name:
              </div>
              <div
                class="flex justify-between items-center font-InterMedium text-base text-white my-4"
              >
                <div class="text-xl">{{ data.game_jam_details.name }}</div>
                <div>
                  <button
                    class="text-nColorYellow border border-nColorYellow rounded-3xl px-4 py-1 text-center text-sm font-InterSemiBold focus:outline-none"
                    @click.once.prevent="goToStep('step1')"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-nColorTextInput rounded-2xl p-4">
              <div class="font-InterMedium text-nColorLightGray">Logo:</div>
              <div
                class="flex justify-between items-center font-InterMedium text-base text-nColorLightGray my-4"
              >
                <div>
                  <div v-if="data.game_jam_details.logo">
                    <img
                      :src="data.game_jam_details.logo"
                      alt="Uploaded logo"
                      width="60"
                      height="60"
                      class="border border-nColorLightGray rounded-xl"
                    />
                  </div>
                  <div v-else>
                    <div class="text-nColorLightGray">No logo uploaded</div>
                  </div>
                </div>
                <div>
                  <button
                    class="text-nColorYellow border border-nColorYellow rounded-3xl px-4 py-1 text-center text-sm font-InterSemiBold focus:outline-none"
                    @click.once.prevent="goToStep('step1')"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-nColorTextInput rounded-2xl p-4">
              <div class="font-InterMedium text-nColorLightGray">
                Game Jam Duration:
              </div>
              <div
                class="flex justify-between items-center font-InterMedium text-base text-white my-4"
              >
                <div class="text-xl">{{ data.game_jam_details.duration }}</div>
              </div>
            </div>
            <div class="bg-nColorTextInput rounded-2xl p-4">
              <div class="font-InterMedium text-nColorLightGray">
                Total Number Of Games:
              </div>
              <div
                class="flex justify-between items-center font-InterMedium text-base text-white my-4"
              >
                <div class="text-xl">
                  {{ data.game_jam_details.number_of_games }}
                </div>
                <div>
                  <button
                    class="text-nColorYellow border border-nColorYellow rounded-3xl px-4 py-1 text-center text-sm font-InterSemiBold focus:outline-none"
                    @click.once.prevent="goToStep('step2')"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-nColorTextInput rounded-2xl p-4">
              <div class="font-InterMedium text-nColorLightGray">
                Each Game Duration:
              </div>
              <div
                class="flex justify-between items-center font-InterMedium text-base text-white my-4"
              >
                <div class="text-xl">
                  <p>{{ data.game_jam_details.each_game_duration }} minutes</p>
                  <p class="text-xs text-nColorLightGray">
                    This depends on the number of games you select.
                  </p>
                </div>
              </div>
            </div>
            <div class="bg-nColorTextInput rounded-2xl p-4">
              <div class="font-InterMedium text-nColorLightGray">
                Number Of Users:
              </div>
              <div
                class="flex justify-between items-center font-InterMedium text-base text-white my-4"
              >
                <div class="text-xl">
                  {{ data.game_jam_details.users }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="font-InterBold text-xl text-white">Game Jam Details</div>
        <div
          class="flex justify-between items-center font-InterMedium text-base text-nColorLightGray my-10"
        >
          <div>Game Jam Name:</div>
          <div>{{ data.game_jam_details.name }}</div>
        </div>
        <div
          class="flex justify-between items-center font-InterMedium text-base text-nColorLightGray my-10"
          v-if="data.game_jam_details.logo"
        >
          <div>Logo:</div>
          <div>
            <img
              :src="data.game_jam_details.logo"
              alt="Uploaded logo"
              width="60"
              height="60"
              class="border border-nColorLightGray rounded-xl"
            />
          </div>
        </div>
        <div
          class="flex justify-between items-center font-InterMedium text-base text-nColorLightGray my-10"
        >
          <div>Game Jam Duration:</div>
          <div>{{ data.game_jam_details.duration }}</div>
        </div>
        <div
          class="flex justify-between items-center font-InterMedium text-base text-nColorLightGray my-10"
        >
          <div>Total number of games:</div>
          <div>{{ data.game_jam_details.number_of_games }}</div>
        </div>
        <div
          class="flex justify-between items-center font-InterMedium text-base text-nColorLightGray my-10"
        >
          <div>Each game duration:</div>
          <div>{{ data.game_jam_details.each_game_duration }} minutes</div>
        </div>
        <div
          class="flex justify-between items-center font-InterMedium text-base text-nColorLightGray my-10"
        >
          <div>Number of users:</div>
          <div class="flex gap-2 items-center">
            <div>{{ data.game_jam_details.users }}</div>
          </div>
        </div> -->
        </div>
        <div class="w-4/12">
          <div class="bg-nColorTextInput rounded-2xl p-4">
            <div class="font-InterBold text-xl text-white">Games Schedule</div>
            <div class="grid gap-2 mt-2">
              <div v-for="item in data.games_schedule" :key="item.slug">
                <div
                  class="bg-nColorButtonText border border-nColorBorder rounded-2xl flex items-start gap-1 relative p-2"
                >
                  <div>
                    <div
                      class="bg-nColorTextInput rounded-xl w-12 h-16 flex justify-center items-center"
                    >
                      <img
                        :src="getGameTile(item.slug)"
                        :alt="item.name"
                        class="w-12"
                      />
                    </div>
                  </div>
                  <div class="w-full flex justify-between gap-2">
                    <div>
                      <div class="text-white font-InterBold text-sm">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <button
              @click.prevent="confirm()"
              class="bg-nColorYellow rounded-3xl w-full py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
            >
              <span> Confirm </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Header from "@/components/CreateGameJam/Header.vue";

export default {
  name: "GameJamsDesktopStep3",
  components: { Header },
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
    };
  },
  created() {
    this.data = {
      game_jam_details: {
        name: this.gameJamData.step1.name,
        duration: this.gameJamData.step2.game_jam_duration.duration,
        number_of_games: this.gameJamData.step2.games.length,
        each_game_duration:
          this.gameJamData.step2.game_jam_duration.durationInMins /
          this.gameJamData.step2.games.length,
        users: this.gameJamData.package.users,
      },
      games_schedule: this.gameJamData.step2.games,
    };
    if (this.gameJamData.step1 && this.gameJamData.step1.logo_square) {
      this.data.game_jam_details.logo = this.gameJamData.step1.logo_square.url;
    }
  },
  computed: {},
  methods: {
    getGameTile(slug) {
      return this.gameJamData.tiles_base_path + slug + ".webp";
    },
    confirm() {
      ApiService.post(apiResource.gameJams.confirmGameJam, {
        step: this.$route.params.step,
        game_jam_id: this.$route.params.game_jam_id,
        data: this.data,
      }).then(() => {
        ApiService.post(apiResource.gameJams.createGameJam, {
          step: this.$route.params.step,
          game_jam_id: this.$route.params.game_jam_id,
        }).then((data) => {
          const txnGameJamId = data.data.data.game_jam_id;
          this.$router.push({
            name: "GameJamDetails",
            params: {
              game_jam_id: txnGameJamId,
              step: "step4",
            },
          });
        });
      });
    },
    goToPricing() {
      console.log("go to pricing");
    },
    goToStep(step) {
      this.$router.push({
        name: "CreateGameJam",
        params: {
          game_jam_id: this.$route.params.game_jam_id,
          step: step,
        },
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.summary {
  height: 100dvh;
}
</style>
