<template>
  <div class="container p-10" v-if="data">
    <div class="flex justify-between">
      <div>
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
          alt="Playda Logo"
          width="130"
          height="56"
          @click.prevent="goToDashboard()"
          class="cursor-pointer"
        />
      </div>
      <div>
        <button
          class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput"
          @click.prevent="signOut()"
        >
          Sign out
        </button>
      </div>
    </div>
    <div class="flex items-start justify-between mt-4">
      <div class="w-1/3 mt-16">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/diy/payment_page_illustration.webp"
          alt="Playda Payment Illustration"
          width="340"
          height="475"
        />
      </div>
      <div class="w-2/3 mt-4">
        <div class="border border-nColorBorder bg-nColorTextInput rounded-3xl">
          <div class="flex justify-between" v-if="data.pricing">
            <div class="w-2/3 grid p-4 border-r border-nColorBorder">
              <div class="w-32">
                <div
                  class="bg-transparent text-nColorLightGray border border-nColorLightGray rounded-2xl font-InterSemiBold text-sm px-6 py-0.5 capitalize text-center"
                >
                  {{ data.pricing[0].name }}
                </div>
              </div>
              <div v-if="data.features">
                <div>
                  <div
                    v-for="feature in data.features"
                    :key="feature.slug"
                    class="my-4"
                  >
                    <p class="font-InterSemiBold text-sm text-nColorLightGray">
                      {{ feature.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(pricing, index) in data.pricing"
              :key="index"
              class="grid text-center cursor-pointer px-10 my-6 w-1/3"
              @click.prevent="selectPricing(index)"
            >
              <div class="flex justify-center font-InterBold text-white">
                <span v-if="getPricing(pricing).discounted_price !== 0">
                  {{ currencySymbol }}{{ getPricing(pricing).discounted_price }}
                </span>

                <!-- If no discount, just show the regular price -->
                <span v-else>
                  {{ currencySymbol }}{{ getPricing(pricing).price }}
                </span>

                <span
                  v-if="getPricing(pricing).discounted_price !== 0"
                  class="line-through-custom text-gray-500 ml-1"
                >
                  {{ currencySymbol }}{{ getPricing(pricing).price }}
                </span>
              </div>
              <div>
                <div
                  v-for="feature in data.features"
                  :key="feature.slug"
                  class="my-4 flex justify-center"
                >
                  <div v-if="pricing[feature.slug] === 'Yes'">
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/diy/plan_page_green_tick.webp"
                      alt="Playda Pricing Feature Yes"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div v-else-if="pricing[feature.slug] === 'No'">
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/diy/plan_page_red_cross.webp"
                      alt="Playda Pricing Feature Yes"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div v-else class="text-nColorLightGray">
                    {{ pricing[feature.slug] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-2 items-center justify-end mt-4">
          <div v-if="codeInvalid" class="flex gap-1 items-center">
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/coupon-invalid.webp"
                alt="Invalid Coupon Code"
                width="20"
                height="20"
              />
            </div>
            <p class="text-nColorRed font-InterRegular text-sm">
              This coupon is invalid
            </p>
          </div>
          <div v-if="codeValid" class="flex gap-1 items-center">
            <div>
              <lottie-animation
                path="lotties/coupon-success.json"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                :width="50"
                :height="50"
              />
            </div>
            <p class="text-nColorSuccessGreen font-InterMedium text-sm">
              {{ validMsg }}
            </p>
          </div>
          <div
            class="bg-nColorTextInput flex gap-2 justify-between items-center p-3 rounded-3xl w-80 h-16"
            v-if="couponApplied"
          >
            <div class="text-nColorYellow font-InterBold px-2">
              {{ code }}
            </div>
            <div>
              <button
                class="font-InterSemiBold px-4 py-1 rounded-3xl text-sm border border-nColorYellow text-nColorYellow bg-nColorTextInput"
                @click.prevent="removeCouponCode()"
              >
                Remove
              </button>
            </div>
          </div>
          <div
            class="bg-nColorTextInput flex gap-2 items-center p-3 rounded-3xl w-80 h-16"
            v-else
          >
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/enter_promo_code.webp"
                alt="Enter Coupon Code"
                width="28"
                height="28"
              />
            </div>
            <div>
              <input
                type="text"
                name="code"
                id="code"
                v-model="code"
                class="textInput w-60"
                placeholder="Enter coupon code"
              />
            </div>
            <div>
              <button
                class="font-InterSemiBold px-4 py-1 rounded-3xl text-sm border border-nColorYellow text-nColorYellow bg-nColorTextInput"
                @click.prevent="applyCouponCode()"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <div class="w-full p-4 font-InterSemiBold text-xs text-white">
          <div
            class="flex justify-between items-center border-b border-nColorBorder py-2"
          >
            <div>Subtotal</div>
            <div>
              <div class="flex justify-center font-InterBold">
                <span v-if="isNumber(summary.subTotal)">{{
                  currencySymbol
                }}</span
                >{{ summary.subTotal }}
              </div>
            </div>
          </div>
          <div
            class="flex justify-between items-center border-b border-nColorBorder py-2"
          >
            <div>Taxes</div>
            <div>
              <div class="flex justify-center font-InterBold">
                <span>{{ currencySymbol }}</span
                >{{ summary.taxes }}
              </div>
            </div>
          </div>
          <div
            class="flex justify-between items-center border-b border-nColorBorder py-2"
            v-if="summary.discount != 0"
          >
            <div>Discount</div>
            <div>
              <div class="flex justify-center font-InterBold">
                <span>{{ currencySymbol }}</span
                >{{ summary.discount }}
              </div>
            </div>
          </div>
          <div
            class="flex justify-between items-center border-b border-nColorBorder py-2"
          >
            <div>Total</div>
            <div>
              <div class="flex justify-center font-InterBold">
                <span v-if="isNumber(summary.total)">{{ currencySymbol }}</span
                >{{ summary.total }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-2 items-center justify-end mt-2">
      <button
        @click.prevent="changePlan()"
        class="border border-nColorYellow rounded-3xl px-12 py-0.5 text-nColorYellow text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
      >
        <span> Change plan </span>
      </button>
      <button
        @click.prevent="initiatePayment()"
        class="bg-nColorYellow rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        v-if="summary.total != 0"
      >
        <span> Pay now </span>
      </button>
      <button
        @click.prevent="continueWithoutPayment()"
        class="bg-nColorYellow rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        v-else
      >
        <span> Continue </span>
      </button>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { ref } from "vue";
import TokenService from "@/common/token.service";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "Desktop",
  components: { LottieAnimation },
  data() {
    return {
      data: null,
      selectedPricing: 0,
      isSubmitLoading: false,
      marketingURL: process.env.VUE_APP_MARKETING_URL,
      contactURL: process.env.VUE_APP_CONTACT_URL,
      scriptUrl: process.env.VUE_APP_RAZORPAY_URL,
      receiptId: null,
      orderDetails: null,
      finalPayment: null,
      code: null,
      codeInvalid: false,
      codeValid: false,
      validMsg: null,
      summary: {
        subTotal: null,
        taxes: 0,
        discount: 0,
        total: null,
      },
      couponApplied: false,
      pricingINR: "INR",
      pricingUSD: "USD",
      userPricing: "INR",
    };
  },
  created() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone != "Asia/Kolkata") {
      this.userPricing = this.pricingUSD;
    }
    ApiService.post(apiResource.checkPricingForUser, {
      pricing_plan: TokenService.getToken("pricing_plan"),
    }).then((data) => {
      if (TokenService.getToken("pricing_plan") === "free_trial") {
        this.$router.replace({
          name: "Dashboard",
        });
      } else {
        this.data = data.data.data;
        const pricing = this.data.pricing[0];

        // Determine keys dynamically based on userPricing
        const priceKey = this.userPricing === "USD" ? "price_usd" : "price_inr";
        const discountedPriceKey =
          this.userPricing === "USD"
            ? "discounted_price_usd"
            : "discounted_price_inr";

        // Populate summary values dynamically
        if (pricing[discountedPriceKey] === 0) {
          this.summary.subTotal = this.summary.total = pricing[priceKey];
        } else {
          this.summary.subTotal = this.summary.total =
            pricing[discountedPriceKey];
        }
      }
    });
  },
  methods: {
    async loadRazorpayScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = this.scriptUrl;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          reject(new Error("Failed to load Razorpay script"));
        };
        document.body.appendChild(script);
      });
    },
    async fetchOrderDetails() {
      this.finalPayment = this.summary.total * 100;
      await ApiService.post(apiResource.gameJams.createRazorpayOrder, {
        amt: this.finalPayment,
        summary: this.summary,
        code: this.code,
        coupon_applied: this.couponApplied,
        currency: this.userPricing,
        pricing_plan: TokenService.getToken("pricing_plan"),
      }).then((data) => {
        this.orderDetails = data.data.data;
        this.receiptId = this.orderDetails.receipt_id;
      });
    },
    async initiatePayment() {
      try {
        await this.loadRazorpayScript();
        await this.fetchOrderDetails();
        const options = {
          key: process.env.VUE_APP_RAZORPAY_KEY_ID,
          amount: this.finalPayment,
          currency: this.userPricing,
          name: "Playda!",
          description: "Playda Game Jam Package - " + this.data.pricing[0].name,
          order_id: this.orderDetails.razorpay_order_response.id,
          image:
            "https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp",
          handler: (response) => {
            this.handlePaymentSuccess(response);
          },
          prefill: {
            name: this.orderDetails.diy_user_name,
            email: this.orderDetails.diy_user_email,
            contact: "",
          },
        };
        const razorpayInstance = new window.Razorpay(options);
        razorpayInstance.open();
      } catch (error) {
        console.log(error.message);
      }
    },
    handlePaymentSuccess(response) {
      ApiService.post(apiResource.gameJams.confirmPayment, {
        receipt_id: this.receiptId,
        payment_response: response,
        pricing_plan: TokenService.getToken("pricing_plan"),
      }).then(() => {
        toast.success("Payment received successfully!", { timeout: 2000 });
        setTimeout(() => {
          this.$router.replace({
            name: "Dashboard",
          });
        }, 2000);
      });
    },
    getBackgroundColorClass(index) {
      const classes = [
        "bg-diy-blue-bg",
        "bg-diy-green-bg",
        "bg-diy-pink-bg",
        "bg-diy-purple-bg",
      ];
      return classes[index % classes.length];
    },
    getTextColorClass(index) {
      const classes = [
        "text-diy-blue-text",
        "text-diy-green-text",
        "text-diy-pink-text",
        "text-diy-purple-text",
      ];
      return classes[index % classes.length];
    },
    selectPricing(index) {
      this.selectedPricing = index;
    },
    getStarted() {
      this.isSubmitLoading = true;
      const pricingArray = ref(this.data.pricing);
      var pricingDetails = pricingArray.value[this.selectedPricing];
      if (pricingDetails.action === "form") {
        window.location.replace(this.contactURL);
      } else {
        TokenService.saveToken("pricing_plan", pricingDetails.slug);
        this.$router.push({
          name: "Register",
        });
      }
    },
    isNumber(value) {
      return typeof value === "number";
    },
    signOut() {
      this.$store
        .dispatch("signOut")
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {});
    },
    changePlan() {
      this.$router.push({ name: "Pricing" });
    },
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    applyCouponCode() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.codeInvalid = false;
      this.codeValid = false;
      ApiService.post(apiResource.applyCouponCode, {
        pricing_plan: TokenService.getToken("pricing_plan"),
        code: this.code,
        timezone: timezone,
      })
        .then((data) => {
          var dataResponse = data.data.data;
          this.codeValid = true;
          this.validMsg = dataResponse.message;
          this.summary.subTotal = dataResponse.sub_total;
          this.summary.taxes = dataResponse.taxes;
          this.summary.discount = dataResponse.discount;
          this.summary.total = dataResponse.total;
          this.couponApplied = true;
        })
        .catch(() => {
          this.codeInvalid = true;
        });
    },
    removeCouponCode() {
      // Determine the pricing keys dynamically based on userPricing
      const priceKey = this.userPricing === "USD" ? "price_usd" : "price_inr";
      const discountedPriceKey =
        this.userPricing === "USD"
          ? "discounted_price_usd"
          : "discounted_price_inr";

      // Check if the discounted price exists and is not zero
      const effectivePrice =
        this.data.pricing[0][discountedPriceKey] !== 0
          ? this.data.pricing[0][discountedPriceKey]
          : this.data.pricing[0][priceKey];

      // Reset the summary values based on the effective price
      this.summary.subTotal = effectivePrice;
      this.summary.taxes = 0;
      this.summary.discount = 0;
      this.summary.total = effectivePrice;

      // Reset coupon-related flags and input
      this.couponApplied = false;
      this.codeInvalid = false;
      this.codeValid = false;
      this.code = null;
    },
    continueWithoutPayment() {
      ApiService.post(apiResource.continueWithoutPayment, {
        pricing_plan: TokenService.getToken("pricing_plan"),
        code: this.code,
        summary: this.summary,
      }).then(() => {
        this.$router.replace({
          name: "Dashboard",
        });
      });
    },
    getPricing(pricing) {
      if (this.userPricing === "INR") {
        return {
          price: pricing.price_inr,
          discounted_price: pricing.discounted_price_inr,
        };
      } else if (this.userPricing === "USD") {
        return {
          price: pricing.price_usd,
          discounted_price: pricing.discounted_price_usd,
        };
      }
      // Default fallback in case userPricing is undefined or invalid
      return {
        price: pricing.price_inr,
        discounted_price: pricing.discounted_price_inr,
      };
    },
  },
  computed: {
    currencySymbol() {
      return this.userPricing === "USD" ? "$" : "₹";
    },
  },
};
</script>

<style scoped lang="postcss">
.container {
  height: 100dvh;
}
.pricing-selected {
  @apply bg-nColorYellow border-l-4 border-r-4 border-nColorYellow;
}
.textInput {
  @apply w-full text-white pt-2 pb-3 focus:outline-none bg-nColorTextInput border-b border-nColorLightGray text-sm;
}
.line-through-custom {
  text-decoration-line: line-through;
  text-decoration-thickness: 0.12rem;
}
</style>
