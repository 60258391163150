<template>
  <div class="container p-10" v-if="data">
    <div class="flex justify-between">
      <div>
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
          alt="Playda Logo"
          width="130"
          height="56"
          @click.prevent="goToDashboard()"
          class="cursor-pointer"
        />
      </div>
      <div>
        <button
          class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput"
          @click.prevent="signOut()"
        >
          Sign out
        </button>
      </div>
    </div>
    <div class="text-white font-InterBold mt-6">
      Choose the perfect plan and let the adventure begin!
    </div>
    <div class="mt-16 relative" style="z-index: 1">
      <div class="absolute -right-10 -top-24" style="z-index: -1">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/diy/plans_page.webp"
          alt="Playda Pricing"
          width="200"
          height="114"
        />
      </div>

      <!-- Main Flex Container -->
      <div
        class="bg-nColorTextInput border border-nColorBorder rounded-3xl flex justify-between"
      >
        <!-- Left Features Column -->
        <div class="p-6 flex-1">
          <p class="text-white font-InterBold pb-10">Compare pricing levels</p>
          <p
            v-for="feature in data.features"
            :key="feature.slug"
            class="text-nColorLightGray font-InterSemiBold mt-6"
          >
            {{ feature.name }}
          </p>
        </div>

        <!-- Pricing Columns -->
        <div
          v-for="pricing in data.pricing"
          :key="pricing.slug"
          class="p-6 flex-1 cursor-pointer"
          @click="selectPackage(pricing.slug)"
          :class="[
            {
              'border-2 border-nColorYellow rounded-3xl':
                selectedPackage === pricing.slug,
              'border-l border-nColorBorder': selectedPackage != pricing.slug,
            },
          ]"
        >
          <div class="grid grid-cols-1 justify-items-center gap-1 w-full">
            <div
              class="text-white rounded-2xl font-InterSemiBold text-base px-6 py-0.5 capitalize text-center"
            >
              {{ pricing.name }}
            </div>
            <p class="text-white font-InterBold text-center w-full text-lg">
              <!-- Show discounted price if available -->
              <span v-if="getPricing(pricing).discounted_price !== 0">
                {{ currencySymbol }}{{ getPricing(pricing).discounted_price }}
              </span>

              <!-- If no discount, just show the regular price -->
              <span v-else>
                {{ currencySymbol }}{{ getPricing(pricing).price }}
              </span>

              <span
                v-if="getPricing(pricing).discounted_price !== 0"
                class="line-through-custom text-gray-500 ml-1"
              >
                {{ currencySymbol }}{{ getPricing(pricing).price }}
              </span>
            </p>

            <div class="w-full">
              <p
                v-for="feature in data.features"
                :key="feature.slug"
                class="text-nColorLightGray font-InterSemiBold mt-6 flex items-center justify-center"
              >
                <span v-if="pricing[feature.slug] === 'Yes'">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/diy/plan_page_green_tick.webp"
                    alt="Playda Pricing Feature Yes"
                    width="20"
                    height="20"
                  />
                </span>
                <span v-else-if="pricing[feature.slug] === 'No'">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/diy/plan_page_red_cross.webp"
                    alt="Playda Pricing Feature No"
                    width="20"
                    height="20"
                  />
                </span>
                <span v-else>
                  {{ pricing[feature.slug] }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-nColorLightGray text-center py-2">
      All prices are exclusive of taxes
    </div>
    <div
      class="bg-nColorTextInput border border-nColorBorder rounded-3xl flex justify-between mt-2"
    >
      <div class="w-1/3 h-full object-cover">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/diy/pricing-banner.webp"
          alt="Pricing banner"
          class="w-full h-full object-cover"
        />
      </div>
      <div class="flex-grow grid gap-1 justify-items-end p-4">
        <div class="text-white font-InterExtraBold text-xl">
          Contact our sales team
        </div>
        <div class="font-InterBold text-nColorLightGray">
          For customized Game Jams or for more users to add to your Game Jams
        </div>
        <div>
          <router-link
            :to="{
              name: 'ContactUs',
            }"
            class="text-center font-ZuumeMedium border border-nColorYellow text-nColorYellow px-10 py-1 rounded-3xl text-3xl"
          >
            Contact Us
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-4 pb-16">
      <button
        @click.prevent="makePayment()"
        class="bg-nColorYellow rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
      >
        <span> Make payment </span>
      </button>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import TokenService from "@/common/token.service";

export default {
  name: "PricingPage",
  components: {},
  data() {
    return {
      data: null,
      selectedPackage: null,
      isSubmitLoading: false,
      pricingINR: "INR",
      pricingUSD: "USD",
      userPricing: "INR",
    };
  },
  created() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone != "Asia/Kolkata") {
      this.userPricing = this.pricingUSD;
    }
    ApiService.get(apiResource.getPricingDetails).then((data) => {
      this.data = data.data.data;
      this.selectedPackage = this.data.pricing[0].slug;
    });
  },
  methods: {
    selectPackage(slug) {
      this.selectedPackage = slug;
    },
    signOut() {
      this.$store
        .dispatch("signOut")
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {});
    },
    makePayment() {
      TokenService.saveToken("pricing_plan", this.selectedPackage);
      this.$router.push({
        name: "Payment",
      });
    },
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    getPricing(pricing) {
      if (this.userPricing === "INR") {
        return {
          price: pricing.price_inr,
          discounted_price: pricing.discounted_price_inr,
        };
      } else if (this.userPricing === "USD") {
        return {
          price: pricing.price_usd,
          discounted_price: pricing.discounted_price_usd,
        };
      }
      // Default fallback in case userPricing is undefined or invalid
      return {
        price: pricing.price_inr,
        discounted_price: pricing.discounted_price_inr,
      };
    },
  },
  computed: {
    currencySymbol() {
      return this.userPricing === "USD" ? "$" : "₹";
    },
  },
};
</script>

<style scoped lang="postcss">
.container {
  height: 100dvh;
}
.line-through-custom {
  text-decoration-line: line-through;
  text-decoration-thickness: 0.12rem;
}
</style>
