<template>
  <div v-if="modal.show" class="modal">
    <!-- Modal -->
    <div class="modal-wrapper fixed inset-0 overflow-y-auto text-gray-800 z-40">
      <div
        class="flex items-end justify-center mobile-height md:h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 relative mt-20 md:mt-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-80"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-nColorTextInput rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-nColorTextInput flex justify-center">
            <div>
              <div
                class="mt-2 px-8 py-1 pb-3 bg-nColorTextInput text-base flex gap-2 font-medium items-center"
              >
                <div class="grid justify-items-center content-center gap-4">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/diy/share-popup-illustration.webp"
                    alt="Illustration"
                    width="140"
                    height="140"
                    class="mt-4"
                  />
                  <div
                    class="px-4 text-white font-InterSemiBold text-lg text-left"
                  >
                    <p class="my-1">
                      Share the QR code/ URL with your audience.
                    </p>
                    <p class="my-1">
                      Start The Game Jam when your audience is ready.
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex justify-center pb-10">
                =
                <button
                  @click="closeModal"
                  type="button"
                  class="font-ZuumeSemiBold px-8 rounded-3xl py-1 text-3xl transform transition-all duration-400 ease-in-out bg-nColorYellow text-nColorButtonText hover:font-bold outline-none focus:outline-none"
                >
                  Got it
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameJamShareModal",
  components: {},
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
  },
};
</script>

<style scoped>
.mobile-height {
  height: 75%;
}
</style>
