export default {
  steps: [
    {
      slug: "step1",
      text: "Add Name & Logo",
    },
    {
      slug: "step2",
      text: "Select Games",
    },
    {
      slug: "step3",
      text: "Finalize",
    },
  ],
};
