<template>
  <div class="p-4">
    <div class="flex gap-2 items-center justify-between py-2">
      <div class="flex gap-2 items-center">
        <p class="font-InterExtraBold text-3xl text-nColorOrange">
          {{ title }}
        </p>
        <div></div>
      </div>
      <div>
        <button
          class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput"
          @click.prevent="signOut()"
        >
          Sign out
        </button>
      </div>
    </div>
    <hr class="mt-2 border-2 border-nColorTextInput" />
  </div>
</template>

<script>
export default {
  name: "GameJamHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped></style>
