<template>
  <div class="dashboard">
    <div class="flex">
      <div>
        <Menu />
      </div>
      <div class="w-full relative" v-if="data">
        <div class="banner rounded-3xl mx-10 mt-10 mb-4">
          <div class="flex gap-4">
            <div
              class="grid justify-between p-8"
              v-if="data['new_user'] === true"
            >
              <div class="w-2/3">
                <p class="font-ZuumeSemiBold text-6xl">Welcome aboard!</p>
                <p class="font-InterSemiBold">
                  Excited to see you here, ready to create your first Game Jam!
                  As a special welcome, we've got a free Game Jam waiting just
                  for you.<br /><br />
                  To get started, simply click on the "Create a Game Jam"
                  button.<br /><br />
                  Let the games begin!
                </p>
              </div>
            </div>
            <div
              class="grid justify-between p-8"
              v-if="data['new_user'] === false"
            >
              <div class="w-2/3">
                <p class="font-ZuumeSemiBold text-6xl">Welcome back!</p>
                <p class="font-InterSemiBold">
                  Hope you enjoyed your first Game Jam experience! Ready to take
                  your engagement to the next level? It's easy to keep the fun
                  going.<br /><br />
                  Click Buy and keep the games rolling!
                </p>
              </div>
            </div>
            <div class="absolute right-40 top-12">
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/home_page_illustration.webp"
                alt="Live Events"
                width="200"
                height="232"
              />
            </div>
            <div id="menu-account" class="absolute right-16 top-16">
              <button
                class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput"
                @click.prevent="signOut()"
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
        <div v-if="data" class="grid grid-cols-2 gap-4 px-10 pb-20 mt-10">
          <!-- Available Game Jams Section -->
          <div
            v-for="gameJam in data['available']"
            :key="gameJam.pricing_id"
            class="rounded-3xl"
          >
            <div
              class="flex justify-between gap-2 p-4 items-center bg-nColorTextInput rounded-t-3xl"
            >
              <div class="w-1/2">
                <p
                  :class="[getBorderColorClass(gameJam.package_slug)]"
                  class="bg-nColorButtonText border rounded-2xl text-white font-InterExtraBold text-base px-3 py-0.5 w-32 text-center"
                >
                  {{ gameJam.package_name }}
                </p>
              </div>
              <div class="w-1/2 flex gap-2 items-center pl-3">
                <div class="w-3 h-3 bg-white rounded-full"></div>
                <p class="font-InterSemiBold text-white text-sm">Unused</p>
              </div>
            </div>
            <div
              class="bg-nColorButtonText p-4 rounded-b-3xl border-2 border-nColorTextInput h-56 grid content-between"
            >
              <div class="flex justify-between gap-2 items-center py-3">
                <div class="w-1/2 pl-3">
                  <div class="text-nColorLightGray font-InterMedium text-sm">
                    Max {{ gameJam.users }} Users
                  </div>
                </div>
                <div class="w-1/2 pl-3">
                  <div class="text-nColorLightGray font-InterMedium text-sm">
                    {{ gameJam.no_of_games }} games
                  </div>
                </div>
              </div>
              <div class="w-full mt-4 relative">
                <button
                  id="create-game-jam"
                  @click.once.prevent="createGameJam(gameJam.pricing_id)"
                  class="w-full bg-nColorYellow rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-2xl font-ZuumeSemiBold focus:outline-none"
                >
                  <span> Create game jam </span>
                </button>
              </div>
            </div>
          </div>
          <!-- Drafts Section -->
          <div
            v-for="jam in data['incomplete']"
            :key="jam.game_jam_id"
            class="rounded-3xl"
          >
            <div
              class="flex justify-between gap-2 p-4 items-center bg-nColorTextInput rounded-t-3xl"
            >
              <div class="w-1/2">
                <p
                  :class="[getBorderColorClass(jam.package.slug)]"
                  class="bg-nColorButtonText border rounded-2xl text-white font-InterExtraBold text-base px-3 py-0.5 w-32 text-center"
                >
                  {{ jam.package.name }}
                </p>
              </div>
              <div class="w-1/2 flex items-center justify-between pl-3">
                <div class="flex gap-2 items-center">
                  <div class="w-3 h-3 bg-nColorOrange rounded-full"></div>
                  <p class="font-InterSemiBold text-nColorOrange text-sm">
                    Incomplete
                  </p>
                </div>
              </div>
            </div>
            <div
              class="bg-nColorButtonText p-4 rounded-b-3xl border-2 border-nColorTextInput h-56 grid content-between"
            >
              <div class="flex justify-between gap-2 items-center pb-3 pt-2">
                <div class="w-1/2 pl-3">
                  <div class="text-white font-InterMedium text-xl">
                    {{ jam.name.name }}
                  </div>
                </div>
                <div class="w-1/2 pl-3">
                  <div class="text-nColorLightGray font-InterMedium text-sm">
                    Max
                    {{ jam.package.users }} Users
                  </div>
                  <div
                    class="text-nColorLightGray font-InterMedium text-sm flex items-center gap-2"
                  >
                    <p>
                      Games added:
                      <span v-if="jam.step3">{{
                        jam.step3.game_jam_details.number_of_games
                      }}</span>
                      <span v-else>0</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex gap-2">
                <button
                  class="font-ZuumeSemiBold bg-nColorYellow text-nColorButtonText py-0.5 rounded-3xl text-2xl w-full"
                  @click.once.prevent="continueGameJam(jam)"
                >
                  Continue
                </button>
                <button
                  class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow py-0.5 rounded-3xl text-2xl w-full"
                  @click.once.prevent="openModal(jam.game_jam_id)"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <!-- Ready Section -->
          <div
            v-for="gameJam in data['ready']"
            :key="gameJam.game_jam_id"
            class="rounded-3xl"
          >
            <div
              class="flex justify-between gap-2 p-4 items-center bg-nColorTextInput rounded-t-3xl"
            >
              <div class="w-1/2">
                <p
                  :class="[getBorderColorClass(gameJam.package.slug)]"
                  class="bg-nColorButtonText border rounded-2xl text-white font-InterExtraBold text-base px-3 py-0.5 w-32 text-center"
                >
                  {{ gameJam.package.name }}
                </p>
              </div>
              <div class="w-1/2 flex items-center justify-between pl-3">
                <div class="flex gap-2 items-center">
                  <div class="w-3 h-3 bg-nColorBlue rounded-full"></div>
                  <p class="font-InterSemiBold text-nColorBlue text-sm">
                    Ready
                  </p>
                </div>
                <div>
                  <p
                    class="font-InterSemiBold text-nColorYellow underline text-sm cursor-pointer"
                    @click.once.prevent="showQRCodeAndLinks(gameJam)"
                  >
                    QR code & Links
                  </p>
                </div>
              </div>
            </div>
            <div
              class="bg-nColorButtonText p-4 rounded-b-3xl border-2 border-nColorTextInput h-56 grid content-between"
            >
              <div class="flex justify-between gap-2 items-center pb-3 pt-2">
                <div class="w-1/2 pl-3">
                  <div class="text-white font-InterMedium text-xl">
                    {{ gameJam.name.name }}
                  </div>
                </div>
                <div class="w-1/2 pl-3">
                  <div class="text-nColorLightGray font-InterMedium text-sm">
                    Max
                    {{ gameJam.step3.game_jam_details.users }} Users
                  </div>
                  <div
                    class="text-nColorLightGray font-InterMedium text-sm flex items-center gap-2"
                  >
                    <p>
                      Games added:
                      {{ gameJam.step3.game_jam_details.number_of_games }}
                    </p>
                    <button
                      class="font-InterSemiBold text-nColorYellow underline text-sm"
                      @click.once.prevent="
                        editReadyGameJam(gameJam.game_jam_id)
                      "
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <div class="relative flex gap-2" ref="dropdownContainer">
                <button
                  class="font-ZuumeSemiBold bg-nColorYellow text-nColorButtonText py-0.5 rounded-3xl text-2xl w-full"
                  @click.prevent="toggleDropdown(gameJam.game_jam_id, $event)"
                >
                  <div class="flex justify-center items-center gap-2">
                    <p>Start Playing</p>
                    <div>
                      <img
                        src="https://playda.s3.ap-south-1.amazonaws.com/diy/dropdown-arrow.webp"
                        alt="Dropdown Arrow"
                        width="14"
                        height="6"
                      />
                    </div>
                  </div>
                </button>
                <div
                  v-if="selectedDropdown === gameJam.game_jam_id"
                  class="absolute bg-nColorTextInput border border-nColorBorder rounded-2xl shadow-md mt-2 top-8"
                  ref="dropdown"
                >
                  <div class="grid p-1 gap-2">
                    <button
                      class="font-InterSemiBold text-nColorYellow border border-nColorTextInput px-2 rounded-3xl text-lg hover:border hover:border-nColorYellow"
                      @click.once.prevent="startGameJam(gameJam.game_jam_id, 0)"
                    >
                      <div class="flex items-center gap-2">
                        <div>
                          <img
                            src="https://playda.s3.ap-south-1.amazonaws.com/diy/start-playing-now.webp"
                            alt="Start Playing Now"
                            width="18"
                            height="18"
                          />
                        </div>
                        <p>Now</p>
                      </div>
                    </button>
                    <button
                      class="font-InterSemiBold text-nColorYellow border border-nColorTextInput px-2 rounded-3xl text-lg hover:border hover:border-nColorYellow"
                      @click.once.prevent="startGameJam(gameJam.game_jam_id, 5)"
                    >
                      <div class="flex items-center gap-2">
                        <div>
                          <img
                            src="https://playda.s3.ap-south-1.amazonaws.com/diy/start-playing-5.webp"
                            alt="Start Playing in 5"
                            width="18"
                            height="18"
                          />
                        </div>
                        <p>In 5 minutes</p>
                      </div>
                    </button>
                  </div>
                </div>
                <button
                  class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow py-0.5 rounded-3xl text-2xl w-full"
                  @click.once.prevent="openReadyModal(gameJam.game_jam_id)"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <!-- Playing Section -->
          <div
            v-for="gameJam in data['playing']"
            :key="gameJam.game_jam_id"
            class="rounded-3xl border border-nColorStatusGreen"
          >
            <div
              class="flex justify-between gap-2 p-4 items-center bg-nColorTextInput rounded-t-3xl"
            >
              <div class="w-1/2">
                <p
                  :class="[getBorderColorClass(gameJam.package.slug)]"
                  class="bg-nColorButtonText border rounded-2xl text-white font-InterExtraBold text-base px-3 py-0.5 w-32 text-center"
                >
                  {{ gameJam.package.name }}
                </p>
              </div>
              <div class="w-1/2 flex items-center justify-between pl-3">
                <div class="flex gap-2 items-center">
                  <div class="w-3 h-3 bg-nColorStatusGreen rounded-full"></div>
                  <p class="font-InterSemiBold text-nColorStatusGreen text-sm">
                    Playing
                  </p>
                </div>
                <div>
                  <p
                    class="font-InterSemiBold text-nColorYellow underline text-sm cursor-pointer"
                    @click.prevent="showQRCodeAndLinks(gameJam)"
                  >
                    QR code & Links
                  </p>
                </div>
              </div>
            </div>
            <div
              class="bg-nColorButtonText p-4 rounded-b-3xl border-2 border-nColorTextInput h-56 grid content-between"
            >
              <div class="flex justify-between gap-2 items-start pb-3 h-28">
                <div class="w-1/2 pl-3">
                  <div class="grid gap-2">
                    <div class="text-white font-InterMedium text-xl">
                      {{ gameJam.name.name }}
                    </div>
                    <div class="text-nColorLightGray font-InterMedium text-sm">
                      Gamers Live:
                      {{ gameJam.gamers_live }}
                    </div>
                    <div class="text-nColorLightGray font-InterMedium text-sm">
                      Total Game Plays:
                      {{ gameJam.total_game_plays }}
                    </div>
                  </div>
                </div>
                <div class="w-1/2 pl-3">
                  <div class="text-nColorLightGray font-InterMedium text-sm">
                    Max
                    {{ gameJam.step3.game_jam_details.users }} Users
                  </div>
                  <div
                    class="text-nColorLightGray font-InterMedium text-sm flex items-center gap-2"
                  >
                    <p>
                      Games added:
                      {{ gameJam.step3.game_jam_details.number_of_games }}
                    </p>
                  </div>
                  <div
                    class="text-nColorLightGray font-InterMedium text-sm flex items-center gap-2"
                  >
                    <p>
                      Game jam duration:
                      {{ gameJam.step3.game_jam_details.duration }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex gap-2">
                <button
                  class="font-ZuumeSemiBold border border-nColorLightGray text-nColorLightGray py-0.5 rounded-3xl text-2xl w-full"
                >
                  Download Report
                </button>
              </div>
            </div>
          </div>
          <!-- Played Section -->
          <div
            v-for="gameJam in data['played']"
            :key="gameJam.game_jam_id"
            class="rounded-3xl"
          >
            <div
              class="flex justify-between gap-2 p-4 items-center bg-nColorTextInput rounded-t-3xl"
            >
              <div class="w-1/2">
                <p
                  :class="[getBorderColorClass(gameJam.package.slug)]"
                  class="bg-nColorButtonText border rounded-2xl text-nColorLightGray font-InterExtraBold text-base px-3 py-0.5 w-32 text-center"
                >
                  {{ gameJam.package.name }}
                </p>
              </div>
              <div class="w-1/2 flex items-center justify-between pl-3">
                <div class="flex gap-2 items-center">
                  <div class="w-3 h-3 bg-nColorLightGray rounded-full"></div>
                  <p class="font-InterSemiBold text-nColorLightGray text-sm">
                    Played on {{ gameJam.ended_at }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="bg-nColorButtonText p-4 rounded-b-3xl border-2 border-nColorTextInput h-52 grid content-between"
            >
              <div class="flex justify-between gap-2 items-start pb-3 pt-2">
                <div class="w-1/2 pl-3 h-28">
                  <div class="text-nColorLightGray font-InterMedium text-xl">
                    {{ gameJam.name.name }}
                  </div>
                  <div class="text-nColorLightGray font-InterMedium text-sm">
                    Average Rating:
                    <p v-if="gameJam.stats.avg_game_rating">
                      {{ gameJam.stats.avg_game_rating }}/5
                      <span class="stars">
                        <span v-for="n in 5" :key="n" class="star">
                          <i
                            :class="{
                              'fas fa-star':
                                n <= Math.floor(gameJam.stats.avg_game_rating),
                              'fas fa-star-half-alt':
                                n ===
                                  Math.ceil(gameJam.stats.avg_game_rating) &&
                                !Number.isInteger(
                                  gameJam.stats.avg_game_rating
                                ),
                              'far fa-star':
                                n > Math.ceil(gameJam.stats.avg_game_rating),
                            }"
                          ></i>
                        </span>
                      </span>
                    </p>
                    <p v-else>N/A</p>
                  </div>
                </div>
                <div class="w-1/2 pl-3 grid gap-3">
                  <div class="text-nColorLightGray font-InterMedium text-sm">
                    Gamers Engaged:
                    {{ gameJam.stats.participated }}
                  </div>
                  <div
                    class="text-nColorLightGray font-InterMedium text-sm flex items-center gap-2"
                  >
                    <p>
                      Total Gameplays:
                      {{ gameJam.stats.gameplays }}
                    </p>
                  </div>
                  <div
                    class="text-nColorLightGray font-InterMedium text-sm flex items-center gap-2"
                  >
                    <p>Stickiness: {{ gameJam.stats.stickiness }}</p>
                    <div class="relative group">
                      <!-- Tooltip trigger (info icon) -->
                      <i
                        class="fas fa-info-circle cursor-pointer text-gray-400"
                        v-tippy="{
                          content:
                            'Percentage of gamers who played a game more than once',
                        }"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex gap-2">
                <button
                  class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow py-0.5 rounded-3xl text-2xl w-full"
                  @click.once.prevent="
                    downloadGameJamReport(gameJam.game_jam_id)
                  "
                >
                  Download Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Coachmarks group="dashboard" :desktop="true" v-if="data" />
    </div>

    <ConfirmationModal
      @closeModal="closeModal"
      @discardIncomplete="discardGameJam()"
      @discardReady="discardReadyGameJam()"
      :modal="modal"
    />
    <QRCodeAndLinksModal
      :modal="qrModal"
      :data="qrAndLinkGameJam"
      @closeModal="closeQRModal"
      v-if="qrAndLinkGameJam"
    />
  </div>
</template>
<script>
import Menu from "@/components/Dashboard/DesktopMenu.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import wizard from "@/common/wizard.config";
import { useToast } from "vue-toastification";
import ConfirmationModal from "@/components/Elements/ConfirmationModal.vue";
import QRCodeAndLinksModal from "@/components/Elements/QRCodeAndLinksModal.vue";
import Coachmarks from "@/components/Coachmarks/Index.vue";
import { directive } from "vue-tippy";

const toast = useToast();

export default {
  directives: {
    tippy: directive,
  },
  name: "Dashboard",
  components: {
    Menu,
    ConfirmationModal,
    QRCodeAndLinksModal,
    Coachmarks,
  },
  data() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    return {
      currentDate: `${day}-${month}-${year}`,
      data: null,
      wizard,
      modal: {
        show: false,
        heading: "Reset Game Jam",
        question: "Are you sure you want to reset this game jam?",
        action: null,
        actionText: "reset",
        showLottie: true,
        lottiePath: "lotties/warning.json",
      },
      qrModal: false,
      discardGameJamId: null,
      qrAndLinkGameJam: null,
      selectedDropdown: null,
    };
  },
  created() {
    ApiService.get(apiResource.getDashboardData, {}).then((data) => {
      this.data = data.data.data;
    });
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  computed: {},
  methods: {
    toggleDropdown(gameJamId, event) {
      event.stopPropagation(); // Prevent the click from bubbling up to the document listener
      // Toggle the selected dropdown
      this.selectedDropdown =
        this.selectedDropdown === gameJamId ? null : gameJamId;
    },
    handleClickOutside(event) {
      // Ensure the ref exists and is a valid DOM element
      const dropdownContainer =
        this.$refs.dropdownContainer instanceof HTMLElement
          ? this.$refs.dropdownContainer
          : this.$refs.dropdownContainer?.[0]; // Handle potential array structure

      // Check if the click is outside the dropdown container
      if (
        this.selectedDropdown &&
        dropdownContainer &&
        !dropdownContainer.contains(event.target)
      ) {
        // Close the dropdown if the click is outside
        this.selectedDropdown = null;
      }
    },
    createGameJam(packageId) {
      ApiService.post(apiResource.gameJams.saveProgress, {
        step: "step1",
        game_jam_id: "-1",
        data: { name: "Untitled" },
        package_id: packageId,
      }).then((data) => {
        var dataResponse = data.data.data;
        this.$router.push({
          name: "CreateGameJam",
          params: {
            game_jam_id: dataResponse.game_jam_id,
            step: "step1",
          },
        });
      });
    },
    continueGameJam(gameJam) {
      const length = wizard.steps.length;
      let step = "step1";

      // First, check if step1's name exists and is "Untitled" in the gameJam object
      if (gameJam.step1 && gameJam.step1.name === "Untitled") {
        step = "step1"; // Go to step1 if its name is "Untitled"
      } else {
        // Iterate through steps to find the next step
        for (let i = 0; i < length; i++) {
          if (
            Object.hasOwn(gameJam, wizard.steps[i].slug) &&
            gameJam[wizard.steps[i].slug] != null
          ) {
            // If it's the last step (step4), remain on this step
            if (i === length - 1) {
              step = wizard.steps[i].slug;
            } else {
              // Move to the next step if it's not the last one
              step = wizard.steps[i + 1].slug;
            }
          }
        }
      }
      this.$router.push({
        name: "CreateGameJam",
        params: {
          game_jam_id: gameJam.game_jam_id,
          step: step,
        },
      });
    },
    showQRCodeAndLinks(gameJam) {
      this.qrAndLinkGameJam = gameJam;
      this.qrModal = true;
    },
    openModal(gameJamId) {
      this.discardGameJamId = gameJamId;
      this.modal.action = "discardIncomplete";
      this.modal.show = true;
    },
    openReadyModal(gameJamId) {
      this.discardGameJamId = gameJamId;
      this.modal.action = "discardReady";
      this.modal.show = true;
    },
    closeModal() {
      this.modal.show = false;
    },
    closeQRModal() {
      this.qrModal = false;
    },

    discardGameJam() {
      ApiService.post(apiResource.gameJams.discardGameJam, {
        game_jam_id: this.discardGameJamId,
      }).then(() => {
        this.discardGameJamId = null;
        toast.success("Game jam reset successfully!", { timeout: 2000 });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    },
    discardReadyGameJam() {
      ApiService.post(apiResource.gameJams.discardReadyGameJam, {
        game_jam_id: this.discardGameJamId,
      }).then(() => {
        this.discardGameJamId = null;
        toast.success("Game jam reset successfully!", { timeout: 2000 });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    },
    showGameJamButton() {
      if (this.data.game_jams_available > 0 && this.data.drafts) {
        return true;
      }
      return false;
    },
    goToPricingPage() {
      window.location.href = process.env.VUE_APP_PRICING_URL;
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    getBorderColorClass(packageName) {
      const classes = [
        { name: "basic", class: "border-nColorBasicBorder" },
        { name: "standard", class: "border-nColorStandardBorder" },
        { name: "premium", class: "border-nColorPremiumBorder" },
      ];
      const foundClass = classes.find((c) => c.name === packageName);
      return foundClass ? foundClass.class : "border-nColorLightGray";
    },
    editReadyGameJam(gameJamId) {
      ApiService.post(apiResource.gameJams.editReadyGameJam, {
        game_jam_id: gameJamId,
      }).then((data) => {
        var dataResponse = data.data.data;
        this.$router.push({
          name: "CreateGameJam",
          params: {
            game_jam_id: dataResponse.game_jam_id,
            step: "step1",
          },
        });
      });
    },
    startGameJam(gameJamId, time) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      ApiService.post(apiResource.startGameJam, {
        game_jam_id: gameJamId,
        time: time,
        timezone: timezone,
      }).then(() => {
        toast.success("Game Jam started successfully!", { timeout: 2000 });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    },
    downloadGameJamReport(gameJamId) {
      ApiService.post(apiResource.downloadGameJamReport, {
        game_jam_id: gameJamId,
      }).then(() => {
        toast.success("Report emailed successfully!", { timeout: 2000 });
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.dashboard {
  height: 100dvh;
}
.banner {
  background: linear-gradient(to bottom, #c1c4be, #fde0b7);
}
/* Basic dropdown styling */
.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 150px;
  padding: 10px;
  border-radius: 5px;
  top: 40px; /* Adjust based on your header size */
  right: 0;
  z-index: 1000;
}

/* Hide the dropdown by default */
.hidden {
  display: none;
}
.pending-borders {
  border: 5px solid #ffcb3b;
  outline: 5px solid #ffdf86;
}
.stars {
  display: inline-block;
  margin-left: 5px; /* Optional, to add space between the rating number and stars */
}

.star .fa-star,
.star .fa-star-half-alt {
  color: gold; /* Filled stars in gold */
}

.star .fa-star.far {
  color: gray; /* Non-filled stars in gray */
}

.star {
  margin-right: 2px; /* Optional, adjust spacing between stars */
}
</style>
