<template>
  <div v-if="modal.show" class="modal">
    <!-- Modal -->
    <div class="modal-wrapper fixed inset-0 overflow-y-auto text-gray-800 z-40">
      <div
        class="flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 relative"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-80"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-nColorTextInput rounded-3xl border border-nColorBorder text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-nColorTextInput flex justify-center">
            <div>
              <div
                class="mt-2 px-4 md:px-8 py-1 pb-3 bg-nColorTextInput text-base flex gap-2 font-medium items-center"
              >
                <div
                  class="grid justify-items-center md:flex md:items-center content-center gap-4 h-full md:h-72"
                >
                  <div class="w-52 md:w-fit flex justify-center">
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/diy/duration_pop_up_illustration.webp"
                      alt="DIY Game Jam Games"
                      class="w-32"
                    />
                  </div>
                  <div class="px-4 font-InterSemiBold">
                    <p class="my-1 text-white text-xl">
                      What duration do you have in mind for the game jam?
                    </p>
                    <div class="grid gap-1 my-1 text-white mt-2">
                      <div class="radio-group">
                        <label
                          v-for="(option, index) in options"
                          :key="index"
                          class="radio-selector"
                          :class="{ selected: selectedOption === option }"
                        >
                          <input
                            type="radio"
                            :value="option"
                            v-model="selectedOption"
                            :id="`radio-${index}`"
                          />
                          <span class="radio-icon"></span>
                          <p class="text-white">{{ option.text }}</p>
                        </label>
                      </div>
                    </div>
                    <button
                      @click="proceed"
                      type="button"
                      class="mt-2 w-full md:w-2/3 font-semibold px-6 rounded-3xl py-2 text-sm uppercase tracking-wider transform transition-all duration-400 ease-in-out bg-nColorYellow text-nColorButtonText hover:font-bold outline-none focus:outline-none"
                      v-if="selectedOption"
                    >
                      Proceed
                    </button>
                    <button
                      disabled
                      type="button"
                      class="mt-2 w-full md:w-2/3 font-semibold px-6 rounded-3xl py-2 text-sm uppercase tracking-wider transform transition-all duration-400 ease-in-out bg-nColorBorder text-nColorButtonText hover:font-bold outline-none focus:outline-none"
                      v-else
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameJamGamesModal",
  components: {},
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          text: "15 minutes (1 Game)",
          duration: "15 minutes",
          durationInMins: 15,
          games: 1,
        },
        {
          text: "30 minutes (2 Games)",
          duration: "30 minutes",
          durationInMins: 30,
          games: 2,
        },
        {
          text: "60 minutes (4 Games)",
          duration: "60 minutes",
          durationInMins: 60,
          games: 4,
        },
      ],
      selectedOption: null,
    };
  },
  methods: {
    proceed() {
      this.$emit("closeModal", this.selectedOption);
    },
  },
};
</script>

<style scoped>
.mobile-height {
  height: 75%;
}
.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-selector {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.radio-selector.selected .radio-icon {
  width: 20px;
  height: 20px;
  background-color: #ffcb3b; /* Selected color */
  border-radius: 50%; /* Make the icon circular */
  border: 1px solid #ffcb3b; /* Optional: matching border color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* Unselected color */
  border: 1px solid #ffcb3b; /* Border for unselected state */
  border-radius: 50%; /* Make the icon circular */
  transition: background-color 0.3s ease; /* Smooth transition */
}

/* Style the radio input to be hidden */
input[type="radio"] {
  display: none;
}

/* When the radio input is checked, add the 'selected' class to its parent */
input[type="radio"]:checked + .radio-icon {
  background-color: #ffcb3b; /* Change the background color when selected */
  border-color: #ffcb3b;
}
</style>
