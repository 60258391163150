<template>
  <div class="summary px-4">
    <div class="text-lg text-white">
      <p class="font-InterExtraBold">
        Step 4: Your Game Jam is ready to share.
      </p>
      <div class="text-nColorLightGray text-sm my-3 font-InterMedium">
        <p>Next Steps:</p>
        <ul>
          <li class="my-1">1. Share the QR and Link with your audience.</li>
          <li class="my-1">2. Click on Finalize to complete the set up.</li>
          <li class="my-1">
            3. Hit 'Start' on the next screen to take the game jam LIVE.
          </li>
        </ul>
      </div>
    </div>
    <div class="grid gap-4 mt-4">
      <div class="bg-nColorTextInput rounded-2xl p-4">
        <p class="text-white font-InterSemiBold">1. Download the QR code.</p>
        <div v-if="qrCode" class="mt-4">
          <div class="grid justify-center gap-2">
            <div class="pb-4">
              <img :src="qrCode" width="320" height="320" class="rounded-2xl" />
            </div>
          </div>
          <div>
            <button
              class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow py-0.5 rounded-3xl text-xl w-full"
              @click.prevent="downloadQR"
            >
              Download QR Code
            </button>
          </div>
        </div>
      </div>
      <div class="bg-nColorTextInput rounded-2xl p-4">
        <p class="text-white font-InterSemiBold">2. Copy & Share the link.</p>
        <div v-if="link" class="mt-4">
          <div class="grid gap-2">
            <div class="pb-4 w-64">
              <p
                class="text-nColorYellow font-InterExtraBold italic break-all pt-6"
              >
                {{ link }}
              </p>
            </div>
          </div>
          <div>
            <button
              class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow py-0.5 rounded-3xl text-xl w-full"
              @click.once.prevent="copyLink"
            >
              Copy Link
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 w-full">
      <div class="relative flex gap-2" ref="dropdownContainer">
        <button
          class="font-ZuumeSemiBold bg-nColorYellow text-nColorButtonText px-6 py-0.5 rounded-3xl text-2xl w-full"
          @click.prevent="toggleDropdown(gameJamId, $event)"
        >
          <div class="flex justify-center items-center gap-2">
            <p>Start Playing</p>
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/dropdown-arrow.webp"
                alt="Dropdown Arrow"
                width="14"
                height="6"
              />
            </div>
          </div>
        </button>
        <div
          v-if="selectedDropdown === gameJamId"
          class="absolute bg-nColorTextInput border border-nColorBorder rounded-2xl shadow-md mt-2 top-8 left-1/2 transform -translate-x-1/2"
          ref="dropdown"
        >
          <div class="grid p-1 gap-2">
            <button
              class="font-InterSemiBold text-nColorYellow border border-nColorTextInput px-2 rounded-3xl text-sm hover:border hover:border-nColorYellow"
              @click.once.prevent="startGameJam(gameJamId, 0)"
            >
              <div class="flex items-center gap-2">
                <div>
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/diy/start-playing-now.webp"
                    alt="Start Playing Now"
                    width="18"
                    height="18"
                  />
                </div>
                <p>Now</p>
              </div>
            </button>
            <button
              class="font-InterSemiBold text-nColorYellow border border-nColorTextInput px-2 rounded-3xl text-sm hover:border hover:border-nColorYellow"
              @click.once.prevent="startGameJam(gameJamId, 5)"
            >
              <div class="flex items-center gap-2">
                <div>
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/diy/start-playing-5.webp"
                    alt="Start Playing in 5"
                    width="18"
                    height="18"
                  />
                </div>
                <p>In 5 minutes</p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <button
        @click.prevent="goToDashboard()"
        class="w-full mt-3 mb-10 bg-nColorYellow rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-2xl font-ZuumeSemiBold focus:outline-none"
      >
        <span> Start Later </span>
      </button>
    </div>
    <GameJamShareModal
      @closeModal="closeGameJamShareModal"
      :modal="gameJamShareModal"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { useToast } from "vue-toastification";
import GameJamShareModal from "@/components/Elements/GameJamShareModal.vue";

const toast = useToast();

export default {
  name: "GameJamsDesktopStep4",
  components: { GameJamShareModal },
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      qrCode: null,
      presignedURL: null,
      link: null,
      gameJamId: this.$route.params.game_jam_id,
      selectedDropdown: null,
      gameJamShareModal: {
        show: false,
      },
    };
  },
  created() {
    this.gameJamShareModal.show = true;
    this.qrCode = this.gameJamData.step4.qrcode;
    this.presignedURL = this.gameJamData.step4.presigned_url;
    this.link = this.gameJamData.step4.link;
  },
  computed: {},
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    copyLink() {
      const textarea = document.createElement("textarea");
      textarea.value = this.link;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast.success("Link copied to clipboard!", { timeout: 2000 });
    },
    downloadQR() {
      ApiService.post(apiResource.downloadQRCode, {
        game_jam_id: this.$route.params.game_jam_id,
      })
        .then((data) => {
          const imageUrl = data.data.data.url; // Presigned URL
          fetch(imageUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.blob(); // Convert response to Blob
            })
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob); // Create a URL for the Blob
              link.download =
                "Playda Game Jam - " + data.data.data.name + ".png"; // Set the file name
              document.body.appendChild(link); // Append to the body
              link.click(); // Trigger download
              document.body.removeChild(link); // Clean up
              URL.revokeObjectURL(link.href); // Clean up the object URL
            })
            .catch((error) => {
              console.error("Download failed:", error); // Log any errors
            });
        })
        .catch((error) => {
          console.error("API call failed:", error);
        });
    },
    startGameJam(gameJamId, time) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      ApiService.post(apiResource.startGameJam, {
        game_jam_id: gameJamId,
        time: time,
        timezone: timezone,
      }).then(() => {
        toast.success("Game Jam started successfully!", { timeout: 2000 });
        setTimeout(() => {
          this.$router.replace({
            name: "Dashboard",
          });
        }, 2000);
      });
    },
    goToDashboard() {
      this.$router.replace({
        name: "Dashboard",
      });
    },
    toggleDropdown(gameJamId, event) {
      event.stopPropagation(); // Prevent the click from bubbling up to the document listener
      // Toggle the selected dropdown
      this.selectedDropdown = gameJamId;
    },
    handleClickOutside(event) {
      // Ensure the ref exists and is a valid DOM element
      const dropdownContainer =
        this.$refs.dropdownContainer instanceof HTMLElement
          ? this.$refs.dropdownContainer
          : this.$refs.dropdownContainer?.[0]; // Handle potential array structure

      // Check if the click is outside the dropdown container
      if (
        this.selectedDropdown &&
        dropdownContainer &&
        !dropdownContainer.contains(event.target)
      ) {
        // Close the dropdown if the click is outside
        this.selectedDropdown = null;
      }
    },
    closeGameJamShareModal() {
      this.gameJamShareModal.show = false;
    },
  },
};
</script>

<style scoped lang="postcss">
.summary {
  height: 100dvh;
}
</style>
