<template>
  <div>
    <Header title="Step 2: Choose Games to add to your Game Jam." />
    <div class="select-games p-4" v-if="data">
      <div class="flex gap-2">
        <div
          id="gj-filter-games"
          v-for="cat in categories"
          :key="cat"
          :class="{
            'bg-nColorLightGray text-nColorButtonText': selectedCat === cat,
            'bg-transparent text-nColorLightGray': selectedCat != cat,
          }"
          class="border border-nColorLightGray rounded-2xl font-InterSemiBold text-sm px-3 py-0.5 capitalize cursor-pointer"
          @click="selectedCat = cat"
        >
          {{ cat }}
        </div>
      </div>
      <div class="mt-4 flex gap-2 pb-8">
        <div class="grid grid-cols-4 gap-2">
          <div
            v-for="(game, index) in getGames"
            :key="game.slug"
            class="bg-nColorTextInput rounded-2xl w-36 h-48 grid justify-items-center content-center gap-4 relative mb-2 cursor-pointer"
            @click.prevent="openGameData(index)"
          >
            <div>
              <img
                :id="index === 2 ? 'gj-game-preview' : ''"
                :src="getGameTile(game.slug)"
                :alt="game.name"
              />
            </div>
            <div
              class="absolute -bottom-1 left-0 bg-nColorAddedGreen bg-opacity-90 w-full rounded-b-2xl py-1 z-10"
              @click.once.prevent.stop="removeGame(game.slug)"
              v-if="isSlugPresent(game.slug)"
            >
              <div class="flex justify-center items-center gap-1">
                <div>
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/diy/added_game.webp"
                    width="15"
                    height="15"
                  />
                </div>
                <div class="text-white font-InterSemiBold text-sm">Remove</div>
              </div>
            </div>
            <div
              class="absolute -bottom-1 left-0 bg-black w-full rounded-b-2xl py-1 z-10"
              @click.once.prevent.stop="addGame(game)"
              v-else
            >
              <div class="flex justify-center items-center">
                <div class="text-nColorYellow font-InterSemiBold text-sm">
                  <span :id="index === 1 ? 'gj-add-game' : ''">+ Add game</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- This section will now stick to the top -->
        <div class="w-4/12">
          <div
            :class="{
              'grid justify-items-center content center':
                selectedGames.length === 0,
            }"
            class="bg-nColorTextInput rounded-2xl sticky top-2"
          >
            <div
              class="grid justify-items-center content-center gap-4 h-96"
              v-if="selectedGames.length === 0"
            >
              <div
                class="px-4 text-nColorLightGray font-InterSemiBold text-sm text-left"
              >
                <p class="my-1">Click on the tile to preview the Game.</p>
                <p class="my-1">As you add the games, they will appear here.</p>
              </div>
            </div>
            <div v-else class="p-4">
              <div class="flex justify-between items-center">
                <div>
                  <div class="font-InterBold text-white text-base">
                    Your Game Jam
                  </div>
                  <div class="text-nColorLightGray text-sm">
                    {{ gameJamDuration.duration }}
                  </div>
                </div>
                <button
                  class="font-ZuumeMedium px-3 rounded-3xl text-base border border-nColorYellow text-nColorYellow bg-nColorTextInput"
                  @click.prevent="editDuration()"
                >
                  Edit Duration
                </button>
              </div>
              <p
                class="font-InterMedium text-nColorLightGray text-sm pt-2"
                v-if="selectedGames.length > 1"
              >
                Use the drag icon to arrange games in the order you'd like
              </p>
              <div class="pt-2">
                <draggable :list="selectedGames" class="grid gap-2">
                  <div v-for="item in selectedGames" :key="item.slug">
                    <div
                      class="bg-nColorButtonText border border-nColorBorder rounded-2xl flex items-start gap-1 relative p-2"
                    >
                      <div>
                        <div
                          class="bg-nColorTextInput rounded-xl w-12 h-16 flex justify-center items-center"
                        >
                          <img
                            :src="getGameTile(item.slug)"
                            :alt="item.name"
                            class="w-12"
                          />
                        </div>
                      </div>
                      <div class="w-full flex justify-between gap-2">
                        <div>
                          <div class="text-white font-InterBold text-sm">
                            {{ item.name }}
                          </div>
                        </div>
                        <div class="grid content-center justify-items-center">
                          <div
                            class="cursor-pointer"
                            @click="removeGame(item.slug)"
                          >
                            <img
                              src="https://playda.s3.ap-south-1.amazonaws.com/diy/remove.webp"
                              alt="DIY Game Jam Remove Game"
                              width="20"
                              height="20"
                            />
                          </div>
                          <div class="mt-4" v-if="selectedGames.length > 1">
                            <img
                              src="https://playda.s3.ap-south-1.amazonaws.com/diy/rearrange.webp"
                              alt="DIY Game Jam Rearrange Game"
                              width="12"
                              height="20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div class="w-full mt-4">
                  <button
                    @click.prevent="goNext()"
                    class="w-full bg-nColorYellow rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
                    v-if="selectedGames.length === this.gameJamDuration.games"
                  >
                    <span> Next </span>
                  </button>
                  <button
                    disabled
                    class="w-full bg-nColorBorder rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
                    v-else
                  >
                    <span> Next </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Coachmarks group="gj-step-2" :desktop="true" v-if="gameJamData" />
      <ExceededLimitModal
        @closeModal="closeExceededLimitModal"
        :modal="exceededLimitModal"
      />
      <ClearGamesModal
        @closeModal="closeClearGamesModal"
        @clearGames="clearGamesConfirm"
        :modal="clearGamesModal"
      />
      <GameJamGamesModal
        @closeModal="closeGameJamGamesModal"
        :modal="gameJamGamesModal"
      />
      <GameDataModal
        :modal="gameDataModal"
        :game="currentGame"
        :games="getGames"
        :previewBasePath="previewBasePath"
        :current-index="currentIndex"
        :selectedGames="selectedGames"
        @closeModal="closeGameDataModal"
        @prev="goToPrevGame"
        @next="goToNextGame"
        @addGame="addGame"
        @removeGame="removeGame"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { defineComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import ExceededLimitModal from "@/components/Elements/ExceededLimitModal.vue";
import ClearGamesModal from "@/components/Elements/ClearGamesModal.vue";
import GameJamGamesModal from "@/components/Elements/GameJamGamesModal.vue";
import GameDataModal from "@/components/Elements/GameDataModal.vue";
import Coachmarks from "@/components/Coachmarks/Index.vue";
import Header from "@/components/CreateGameJam/Header.vue";

export default defineComponent({
  name: "GameJamsDesktopStep2",
  components: {
    draggable: VueDraggableNext,
    ExceededLimitModal,
    ClearGamesModal,
    GameJamGamesModal,
    GameDataModal,
    Coachmarks,
    Header,
  },
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      categories: ["all", "action", "puzzle", "trivia", "word", "sport"],
      selectedCat: "all",
      selectedGames: [],
      currentIndex: 0,
      exceededLimitModal: {
        show: false,
        heading: "4 games added",
        question:
          "You have added the maximum number of games in this Game Jam.",
        showLottie: true,
        lottiePath: "lotties/exceeded-limit.json",
      },
      clearGamesModal: {
        show: false,
        heading: "Clear Games",
        question:
          "Editing duration will remove the games that you have added. Do you want to continue?",
        showLottie: true,
        lottiePath: "lotties/exceeded-limit.json",
      },
      gameJamGamesModal: {
        show: false,
      },
      gameDataModal: false,
      previewBasePath: null,
      tileBasePath: null,
      gameJamDuration: {},
    };
  },
  created() {
    ApiService.get(apiResource.gameJams.getGames, {}).then((data) => {
      this.data = data.data.data.games;
      this.previewBasePath = data.data.data.preview_base_path;
      this.tileBasePath = data.data.data.tiles_base_path;
    });
    if (this.gameJamData.step2 && this.gameJamData.step2 != null) {
      this.selectedGames = this.gameJamData.step2.games;
      this.gameJamDuration = this.gameJamData.step2.game_jam_duration;
    } else {
      this.gameJamGamesModal.show = true;
    }
  },
  computed: {
    getGames() {
      if (!this.data) return [];
      if (this.selectedCat === "all") {
        return Object.values(this.data).flat();
      }
      return this.data[this.selectedCat] || [];
    },
    currentGame() {
      return this.getGames[this.currentIndex] || null;
    },
  },
  methods: {
    getGameTile(slug) {
      return this.tileBasePath + slug + ".webp";
    },
    openGameData(index) {
      this.currentIndex = index;
      this.gameDataModal = true;
    },
    closeGameDataModal() {
      this.gameDataModal = false;
    },
    goToPrevGame() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    goToNextGame() {
      if (this.currentIndex < this.getGames.length - 1) {
        this.currentIndex++;
      }
    },
    openExceededLimitModal() {
      this.exceededLimitModal.show = true;
    },
    closeExceededLimitModal() {
      this.exceededLimitModal.show = false;
    },
    closeClearGamesModal() {
      this.clearGamesModal.show = false;
    },
    closeGameJamGamesModal(selectedOption) {
      this.gameJamGamesModal.show = false;
      this.gameJamDuration = selectedOption;
      this.exceededLimitModal.heading = `${selectedOption.games} games added`;
    },
    addGame(game) {
      // Check if there are already 4 games in the array
      if (this.selectedGames.length >= this.gameJamDuration.games) {
        if (this.gameDataModal) {
          this.gameDataModal = false;
        }
        this.openExceededLimitModal();
        return;
      }
      // Add the new game
      this.selectedGames.push({ ...game, duration: 0 });
      this.updateGameDurations();
    },
    updateGameDurations() {
      const numGames = this.selectedGames.length;
      let duration;

      // Set the duration based on the number of games
      switch (numGames) {
        case 1:
          duration = this.gameJamDuration.durationInMins;
          break;
        case 2:
          duration = this.gameJamDuration.durationInMins / 2;
          break;
        case 3:
          duration = this.gameJamDuration.durationInMins / 3;
          break;
        case 4:
          duration = this.gameJamDuration.durationInMins / 4;
          break;
      }

      // Assign the duration to each game in the array
      this.selectedGames.forEach((game) => {
        game.duration = duration;
      });
    },
    removeGame(slug) {
      this.selectedGames = this.selectedGames.filter(
        (game) => game.slug !== slug
      );
    },
    clearGames() {
      this.selectedGames = [];
    },
    clearGamesConfirm() {
      this.selectedGames = [];
      this.clearGamesModal.show = false;
      this.gameJamGamesModal.show = true;
    },
    isSlugPresent(slug) {
      return this.selectedGames.some((game) => game.slug === slug);
    },
    goNext() {
      ApiService.post(apiResource.gameJams.saveProgress, {
        step: this.$route.params.step,
        game_jam_id: this.$route.params.game_jam_id,
        data: {
          games: this.selectedGames,
          game_jam_duration: this.gameJamDuration,
        },
      }).then((data) => {
        var dataResponse = data.data.data;
        this.$router.push({
          name: "CreateGameJam",
          params: {
            game_jam_id: dataResponse.game_jam_id,
            step: "step3",
          },
        });
      });
    },
    editDuration() {
      this.clearGamesModal.show = true;
    },
  },
});
</script>

<style scoped lang="postcss">
.select-games {
  height: 100dvh;
}
</style>
